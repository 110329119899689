import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'Dashboard',
        isTitle: true
    },
    {
        id: 2,
        label: 'Dashboard',
        icon: 'bxs-dashboard',
        link: '/',
    },
    {
        id: 1,
        label: 'Worker List',
        isTitle: true
    },
    {
        id: 2,
        label: 'Worker List',
        icon: 'bx-user',
        link: '/user',
    },
    {
        id: 1,
        label: 'Contact And Follow List',
        isTitle: true
    },
    {
        id: 3,
        label: 'Follow List',
        icon: 'bx-user-plus',
        link: '/follow',

    },
    {
        id: 4,
        label: 'Contact List',
        icon: 'mdi-account-group-outline',
        link: '/contact',

    },
    {
        id: 1,
        label: 'Scan Card List',
        isTitle: true
    },
    {
        id: 5,
        label: 'Card List',
        icon: 'bx-user-pin',
        link: '/card',

    },
    {
        id: 1,
        label: 'Meet List',
        isTitle: true
    },
    {
        id: 6,
        label: 'Meet List',
        icon: 'bx-group',
        link: '/meet',

    },
];

