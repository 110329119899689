import { Injectable } from '@angular/core';
import { BaseService } from './base.service';


@Injectable({ providedIn: 'root' })
export class CompanyService {
    constructor(
        private _baseService: BaseService
    ) { }

    getMyCompanies() {
        return this._baseService.request('GET', 'company/my-list/companies', null, null);
    }

    getMemberCompanies(id) {
        return this._baseService.request('GET', 'company/' + id + '/members', null, null);
    }

    getContactsAndFlowerData(id, params) {
        return this._baseService.request('GET', 'wdash/chart/flower-and-contacts/' + id, null, params);
    }


    getMeetList(id) {
        return this._baseService.request('GET', 'wdash/meet/' + id, null, null);
    }

    getMeetDetay(id, meetid) {
        return this._baseService.request('GET', 'wdash/meet/' + id + '/' + meetid, null, null);
    }


    getNoteList(id) {
        return this._baseService.request('GET', 'wdash/note/' + id, null, null);
    }
}

