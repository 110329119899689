import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class BaseService {
  constructor(private http: HttpClient) { }

  private createTokenOptions() {
    return new HttpHeaders()
      .set("Authorization", localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : "")
      .set("Access-Control-Allow-Origin", "*")
      .set("Access-Control-Allow-Methods", "*")
      .set("Access-Control-Allow-Headers", "*");
  }

  public request<T>(
    method: any,
    end_point: any,
    data: any,
    params: any = {}
  ): Observable<T> {
    return this.http.request<T>(method, `${environment.baseUrl}${end_point}`, {
      body: data,
      headers: this.createTokenOptions(),
      params: params,
    });
  }
}