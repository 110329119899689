import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;

  isLoggedIn = false;
  constructor(
    private _baseService: BaseService,
    private _router: Router,
    private _toast: ToastrService
  ) {
    this.currentUserSubject = new BehaviorSubject<any>(
      localStorage.getItem("accessToken")
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }
  isAuthenticated() {
    return this.isLoggedIn;
  }

  sendOtp(data: any) {
    var res = this._baseService.request(
      'POST',
      'auth/send-otp',
      { phone: data, type: "LOGIN" },
    );
    return res;
  }
  login(data: any) {
    const respone: any = this._baseService.request(
      'POST',
      'auth/login', data
    ).subscribe((res: any) => {
      localStorage.setItem('accessToken', res.accessToken);
      localStorage.setItem('currentUser', JSON.stringify(res.user))
      this.currentUserSubject.next(res);
      this._router.navigateByUrl('/');
    },
      (err: any) => {
        this._toast.error(err);
      });
  }
  async logout() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('selectedCompany');
    this.currentUserSubject.next(null);
    this._router.navigateByUrl('auth/login');
  }

}