import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(private authService: AuthService, private router: Router) { };
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): any {
    let data: any = JSON.parse(localStorage.getItem('currentUser')!)
    let token: any = localStorage.getItem('accessToken')
    if (token && data) {
      if (data?.status !== 'BASIC' && data.phone === localStorage.getItem('phone')) {
        return true
      } else {
        this.router.navigate(['/auth/login']);
      }
    } else {
      this.router.navigate(['/auth/login']);
    }
  }

}